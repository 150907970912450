.wrapper {
  box-sizing: border-box;
  display: block;

  font-size: calc(2 * var(--s));

  color: var(--colorBitcoinBlue);
  width: 100%;
}

.buttonWrapper {
  box-sizing: border-box;
  position: relative;

  display: inline-block;
  max-width: 80%;
  cursor: pointer;
  opacity: 0.5;
}

.toggleDetails {
  box-sizing: border-box;

  display: inline-block;

  line-height: calc(2 * var(--s));
  text-decoration: none;
  padding: calc(0.75 * var(--s)) calc(4 * var(--s));

  vertical-align: middle;
  white-space: normal;
  text-align: right;
  word-wrap: break-word;

  /* for arrow */
  padding-right: calc(3.5 * var(--s));
  width: 100%;
  text-indent: calc(-2 * var(--s));
}

/* empty arrow div */
.arrow {
  box-sizing: border-box;

  width: calc(2 * var(--s));
  height: calc(2 * var(--s));
  position: absolute;
  top: calc(50% - (1 * var(--s)));
  right: calc(0.5 * var(--s));
  vertical-align: middle;
  line-height: calc(2 * var(--s));

  /* rotation point */
  transform-origin: 50% 50%;
  /* animate back and forward */
  transition: transform 0.1s linear;

  /* starting position pointing to right when collapsed */
  transform: rotate(-90deg);
  margin: 0;
}

/* actual arrow drawn */
.arrow:before {
  content: '';
  position: absolute;
  top: calc(0.3 * var(--s));
  /* left side to top left side of triangle in down position */
  left: calc(0.3 * var(--s));
  width: 0;
  height: 0;
  border-left: calc(0.7 * var(--s)) solid transparent;
  border-right: calc(0.7 * var(--s)) solid transparent;
  border-bottom: 0 solid transparent;
  vertical-align: middle;

  /* visible arrow part */
  border-top: calc(1.4 * var(--s)) solid var(--colorBitcoinBlue);
  margin: 0;
  /* arrow a bit lighter than text since it's solid */
  opacity: 0.7;
}

/* rotate when open */
.down {
  transform: rotate(0deg);
}

.details {
  /* just basic block in case styling is custom */
  margin-top: calc(1 * var(--s));
}

.details > p {
  box-sizing: border-box;
  text-align: left;
  display: inline-block;
  background-color: var(--colorDarker0_5);
  border-radius: calc(0.5 * var(--s));
  padding: calc(2 * var(--s)) calc(3 * var(--s));
  padding-right: calc(2 * var(--s));

  /* margin: calc(1 * var(--s)) calc(4 * var(--s)); */
  margin-top: 0;
  max-width: 66vw;
}

.details > p > span {
  --offsetWrap: calc(2 * var(--s));
  display: block;
  text-indent: calc(-1 * var(--offsetWrap));
  padding: calc(1 * var(--s)) 0;
  padding-left: var(--offsetWrap);
  white-space: pre-wrap;
}

/* increase opacity on hover */
.buttonWrapper:hover {
  opacity: 1;
}
