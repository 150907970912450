.wrapper {
  display: inline-block;
  box-sizing: border-box;

  --inputSize: calc(2.3 * var(--s));
  font-size: var(--inputSize);
  color: var(--colorBitcoinBlue);
  align-self: flex-end;
  text-align: right;
}

.shiftRight {
  box-sizing: border-box;

  width: 100%;
  padding-left: var(--inputSize);
  position: relative;
  /* padding-top: calc(1.3 * var(--inputSize)); */
}

.label {
  box-sizing: border-box;
  padding-bottom: calc(0.5 * var(--s));
  /* width: 100%; */
  /* top: 0; */
  /* bottom: 0; */

  text-indent: calc(-1 * var(--inputSize));

  left: 0;
  right: 0;
  font-size: var(--inputSize);
  /* position: absolute; */
  display: block;
  color: var(--colorHeavyText);
  opacity: 0.7;
  text-align: left;

  /* so next line character works */
  white-space: pre-wrap;
}

.textarea {
  display: block;
  width: calc(100% - calc(0 * var(--s)));
}

.button {
  display: inline-block;
  margin-right: 0;
}

.bonusInformation {
  margin: calc(1 * var(--s));
}

.invisible {
  /* visibility: hidden; */
  display: none;
}

/* ------------------------------- suggestions ------------------------------ */

.suggestions {
  box-sizing: border-box;
  position: absolute;
  /* border: 2px solid blue; */
  width: calc(100% - (1 * var(--inputSize)));
  margin-top: calc(0.5 * var(--s));
  padding-top: calc(0.5 * var(--s));
  padding-bottom: calc(0.5 * var(--s));
  max-height: calc(10 * var(--inputSize));
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  background-color: var(--colorBackground);
  text-align: left;
}

.suggestions__item {
  box-sizing: border-box;
  margin: calc(0.5 * var(--s)) calc(1 * var(--s));
  padding: calc(0.5 * var(--s)) calc(1 * var(--s));
  color: var(--colorMediumText);
  cursor: pointer;
  font-size: calc(0.9 * var(--inputSize));
  text-indent: calc(-2 * var(--s));
  padding-left: calc(2.5 * var(--s));
}

.suggestions__item:hover {
  background-color: var(--colorDarker0_5);
}

.suggestions__separator {
  display: block;
  position: relative;
  width: 2%;
  height: 0;
  border: 1px solid var(--colorDarker0_5);
  margin: 0 auto;
}
