.wrapper {
  color: var(--colorBitcoinBlue);
  font-size: calc(2.5 * var(--s));
  text-align: right;
  margin: calc(2 * var(--s));
}

.title {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  font-size: calc(4 * var(--s));
  /* margin: calc(3 * var(--s)); */
  margin-bottom: calc(3 * var(--s));
  cursor: default;
  color: var(--colorBitcoinBlue);
  text-align: right;
  max-width: calc(50 * var(--s));
  padding-right: calc(6 * var(--s));
  margin-top: calc(-6 * var(--s));
}

.title::after {
  box-sizing: border-box;

  content: '';
  --offset: calc(0.75 * var(--s));
  top: var(--offset);
  right: calc(2.5 * var(--s));
  height: calc(100% - (2.1 * var(--offset)));
  position: absolute;
  border-right: solid calc(2 * var(--s)) var(--colorBitcoinBlue);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: calc(2 * var(--s));
  justify-content: flex-end;
}

.buttonWrapper {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  justify-content: flex-end;

  /* margin: calc(4 * var(--s)); */
}

.buttonWrapper > div {
  box-sizing: border-box;
  margin: calc(1 * var(--s)) calc(2 * var(--s));
}

.describe {
  cursor: default;
  padding: calc(1 * var(--s));
}

.orange {
  color: var(--colorBitcoinOrange);
  text-shadow: none;
}

.spacer {
  margin-top: 30vh;
}

.backup {
  font-size: calc(2.5 * var(--s));
  border-radius: calc(0.5 * var(--s));
  padding: calc(1 * var(--s)) calc(2 * var(--s));
  margin: calc(2 * var(--s));
  color: var(--colorBitcoinOrange);
  background-color: var(--colorBackground);
  width: 70%;
  cursor: default;
  text-align: center;
}

.restoreBackup {
  width: 70%;
  color: var(--colorBitcoinOrange);
  caret-color: var(--colorBitcoinOrange);
  height: calc(20 * var(--s));
  border: calc(1 * var(--s)) solid white;
  line-height: calc(4 * var(--s));
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: normal;
  box-sizing: border-box;
  margin: calc(2 * var(--s));
}

.restoreBackup::placeholder {
  color: var(--colorBitcoinOrange);
  opacity: 0.5;
}

@media screen and (min-aspect-ratio: 14/10) {
  .contentWrapper {
    /* align-items: center; */
  }

  .buttonWrapper {
    /* margin-right: 0; */
    /* align-items: center; */
  }
}
