.wrapper {
  box-sizing: border-box;
  margin: 0 0.5%;
  font-size: calc(2.5 * var(--s));
  color: var(--colorBitcoinBlue);
  text-align: center;
}

.top {
  box-sizing: border-box;
  background-color: var(--colorBitcoinBlue);
  margin-top: calc(0 * var(--s));
  margin-bottom: calc(2 * var(--s));
  padding: calc(3 * var(--s));
  padding-top: 15vh;
  padding-bottom: 2vh;
}

.top__title {
  box-sizing: border-box;
  font-size: calc(5 * var(--s));
  color: var(--colorBackground);
}

.top__title span {
  color: var(--colorBitcoinOrange);
}

.contentWrapper {
  padding: 0;
}

.from {
  display: inline-block;
  margin: 5vh calc(1 * var(--s));
  margin-bottom: 1vh;
  width: 90vmin;
  text-align: left;
}

.from__alias {
  color: var(--colorBitcoinBlue);
}

.from__label {
  color: var(--colorMediumText);
}

.from__address {
  display: inline-block;
  color: var(--colorLightText);
  text-indent: calc(2 * var(--s));
  -webkit-user-select: text;
  -moz-user-select: text;
}

.buttonWrapper {
  display: inline-block;
  min-width: calc(15 * var(--s));
}

/* ------------------------- address balance ------------------------- */

.total {
  vertical-align: middle;
  padding: calc(2 * var(--s));
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.total__value {
  font-size: calc(4 * var(--s));
  box-sizing: border-box;
  background-color: var(--colorDarker0_5);
  border-radius: calc(1 * var(--s));
  padding: calc(0.25 * var(--s)) calc(1 * var(--s));
  text-indent: 0;
  vertical-align: middle;
}

.total__units {
  box-sizing: border-box;
  margin-left: calc(1 * var(--s));
  vertical-align: middle;
  font-size: calc(3 * var(--s));
}

/* ------------------------- address to withdraw to ------------------------- */

.withdraw_control {
  display: inline-block;
  width: 90vmin;
  margin: 3vh calc(1 * var(--s));
}

/* -------------------------- wide screen settings -------------------------- */

@media screen and (min-aspect-ratio: 14/10) {
  .wrapper {
    transform-origin: top;
    transform: scale(var(--horizontalScale));
    text-align: center;
  }

  .top {
    display: inline-block;
    text-align: center;
    margin-bottom: calc(5 * var(--s));
    width: 100%;
  }

  .top__title {
    display: inline-block;
    padding: calc(1 * var(--s)) calc(5 * var(--s));
    line-height: 100%;
  }

  .contentWrapper {
    padding: calc(1 * var(--s)) calc(2 * var(--s));
  }
}
