/*
  .roundWrap - outter most lowst div that handles background color & clicking animation

  .roundButton - inner div that handles text, arrows, and their colors

  .overhead - inner most div to handle hover darkness/brightness that covers text & arrows
*/
.roundButtonWrap {
  display: inline-block;
  position: static;
  background-color: var(--colorThisButton);
  border-radius: calc(0.5 * var(--sizeButton));
  margin: calc(1 * var(--sizeButton));
  text-decoration: none;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  font-size: calc(2.9 * var(--sizeButton));
}

.roundButton {
  color: var(--colorThisButtonText);
  display: inline-block;
  position: relative;
  padding: calc(0.7 * var(--sizeButton)) calc(3 * var(--sizeButton));
  border-radius: calc(0.5 * var(--sizeButton));
  /* was sized as 2.9 but js code resizes it using params to 2.5 default */

  vertical-align: middle;
  /* white-space: nowrap; */
}

.roundButtonWrap.disabledWrap {
  filter: saturate(0);
}

.overhead {
  display: inline-block;
  position: absolute;
  border-radius: calc(0.5 * var(--sizeButton));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nextArrowPad {
  /* prevents arrow from wrapping by placing it inside padding */
  padding-right: calc(5 * var(--sizeButton));
}
.nextArrow {
  display: block;
  white-space: nowrap;
  content: '';
  float: right;
  vertical-align: top;
  /* prevents arrow from wrapping */
  margin-right: calc(-3 * var(--sizeButton));
  margin-left: calc(-2 * var(--sizeButton));
  margin-top: calc(1 * var(--sizeButton));
  width: 0;
  height: 0;
  border-top: calc(0.75 * var(--sizeButton)) solid transparent;
  border-left: calc(1.5 * var(--sizeButton)) solid var(--colorThisButtonText);
  border-bottom: calc(0.75 * var(--sizeButton)) solid transparent;
}

.backArrowPad {
  /* back button color change */
  color: var(--colorThisButton);

  /* prevents arrow from wrapping */
  padding-left: calc(5 * var(--sizeButton));
}

.backArrow {
  display: inline-block;
  content: '';
  float: left;
  vertical-align: middle;
  /* prevents arrow from wrapping */
  margin-left: calc(-3 * var(--sizeButton));
  margin-right: calc(-2 * var(--sizeButton));
  margin-top: calc(1 * var(--sizeButton));
  width: 0;
  height: 0;
  border-top: calc(0.75 * var(--sizeButton)) solid transparent;
  border-right: calc(1.5 * var(--sizeButton)) solid var(--colorThisButton);
  border-bottom: calc(0.75 * var(--sizeButton)) solid transparent;
}

.backWrapper {
  background-color: var(--colorTransparent);
  box-shadow: 0 0 0 calc(0.2 * var(--sizeButton)) var(--colorThisButton) inset;
}

.minorWrapper {
  background-color: var(--colorTransparent);
}

.minorButton {
  color: var(--colorThisButton);
  box-shadow: 0 0 0 calc(0.2 * var(--sizeButton)) var(--colorThisButton) inset;
  color: var(--colorThisButton);
}

/* ---------------------------------- hover --------------------------------- */

.overhead:hover {
  background-color: var(--colorDarker0_5);
}

.disabled:hover .overhead {
  background-color: transparent;
}

.minorWrapper:hover .overhead,
.backWrapper:hover .overhead {
  background-color: var(--colorBrighter2);
}

/* --------------------------------- active --------------------------------- */

.roundButtonWrap:active {
  /* transform: translateY(calc(0.2 * var(--sizeButton))); */
  transform: translateY(calc(0.1 * var(--s)));
}

.roundButtonWrap.disabledWrap:active {
  transform: none;
}
