.wrapper {
  box-sizing: border-box;

  margin: 0 calc(2 * var(--s));
  font-size: calc(2.5 * var(--s));
  color: var(--colorBitcoinBlue);
  display: grid;

  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto auto;

  grid-gap: calc(1 * var(--s)) calc(1 * var(--s));

  grid-template-areas:
    'title            title'
    'subtitle         subtitle'
    'changes          changes'
    'buttonWrapper buttonWrapper'
    'editor           editor'
    'pastList         pastList'
    'pastList         pastList';

  align-items: center;
}

.title {
  grid-area: title;
  margin-bottom: calc(2 * var(--s));
}

.title {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  font-size: calc(4 * var(--s));
  margin-bottom: calc(3 * var(--s));
  cursor: default;
  color: var(--colorBitcoinBlue);
  text-align: right;
  max-width: calc(50 * var(--s));
  padding-right: calc(6 * var(--s));
  margin-top: calc(-6 * var(--s));
  margin-right: calc(-2 * var(--s));
  margin-left: auto;
}

.title::after {
  box-sizing: border-box;

  content: '';
  --offset: calc(0.75 * var(--s));
  top: var(--offset);
  right: calc(2 * var(--s));
  height: calc(100% - (2.1 * var(--offset)));
  position: absolute;
  border-right: solid calc(2 * var(--s)) var(--colorBitcoinBlue);
}

.subtitle {
  grid-area: subtitle;
  font-size: calc(2 * var(--s));
  /* margin: calc(1 * var(--s)) calc(1 * var(--s)); */
  margin-top: calc(-4 * var(--s));
  padding-bottom: calc(1 * var(--s));
  opacity: 0.5;
  text-align: right;
  max-width: calc(50 * var(--s));
  margin-left: auto;
}

.changes {
  padding-top: calc(3 * var(--s));
  grid-area: changes;
  width: 100%;
  text-align: left;
}
.pastList {
  grid-area: pastList;
}

.buttonWrapper {
  grid-area: buttonWrapper;
  padding: calc(3 * var(--s)) 0;
  padding-bottom: calc(9 * var(--s));
}

.buttonWrapper > div {
  margin: 0;
  margin-left: calc(2 * var(--s));
  margin-top: calc(1 * var(--s));
  margin-bottom: calc(1 * var(--s));
}

.editor {
  align-self: center;
  grid-area: editor;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 30% 2% calc(65.5% - (5 * var(--s))) 2.5% calc(
      5 * var(--s)
    );
  grid-template-rows: auto calc(3.5 * var(--s));
  grid-template-areas:
    'editorNetwork    .             editorAddress   .               btnAdd'
    'btnDelete        .             .               .               .';
  grid-row-gap: calc(0.5 * var(--s));
  justify-items: start;
  align-items: center;
}

/* planned updates list */

.updateItem {
  box-sizing: border-box;

  width: 100%;
  display: grid;
  grid-template-columns: calc(5 * var(--s)) auto;
  grid-template-columns: calc(5 * var(--s)) auto;
  grid-template-areas: 'updateCancel updateInfo';
  justify-items: center;
  align-items: center;
  background-color: var(--colorDarker0_5);
  border-radius: calc(0.5 * var(--s));
  margin-top: calc(1 * var(--s));
  padding: calc(0.5 * var(--s)) calc(1 * var(--s));
  cursor: pointer;
}
.updateItem:hover {
  background-color: var(--colorDarker1);
}

.updateCancel {
  justify-self: start;
  grid-area: updateCancel;
  text-align: center;
  color: var(--colorBackground);
  background-color: var(--colorTransparent);
  transform: scale(0.9);
}
.updateCancel:hover {
  background-color: var(--colorWarning);
}

.updateInfo {
  justify-self: start;
  width: 100%;
  grid-area: updateInfo;
  text-align: left;
  white-space: pre-wrap;
}
.updateInfo span {
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}
.updateInfo i {
  font-style: normal;
  color: var(--colorDarker4);
}

/* textarea editor */

.editorNetwork {
  grid-area: editorNetwork;
  width: 100%;
  color: var(--colorBitcoinOrange);
  display: block;
  position: relative;
}

.editorAddress {
  grid-area: editorAddress;
  width: 100%;
  color: var(--colorBitcoinOrange);
  display: block;
  position: relative;
}

.editorAddress textarea,
.editorNetwork textarea {
  width: 100%;
  color: var(--colorBitcoinOrange);
}

.editorAddress aside,
.editorNetwork aside {
  top: calc(-2.1 * var(--s));
  line-height: calc(2 * var(--s));
  left: calc(0.5 * var(--s));
  font-size: calc(2 * var(--s));
  position: absolute;
  display: block;
  color: var(--colorBitcoinBlue);
  opacity: 0.7;
  text-align: left;
}

/* add changes button */
.btnAdd {
  grid-area: btnAdd;
  background-color: var(--colorBitcoinOrange);
}
.btnAdd {
  transform: scale(1.2);
}
.btnAdd:active {
  transform: translateY(calc(0.2 * var(--s))) scale(1.2);
}
.btnAdd:hover aside {
  transform: scale(0.8);
}
.btnAdd:hover {
  background-color: var(--colorBitcoinBlue);
}

/* delete network history button */

.btnDelete {
  grid-area: btnDelete;
  justify-self: end;
  font-size: calc(2 * var(--s));
  cursor: pointer;
  border-radius: calc(1.5 * var(--s));
  background-color: var(--colorDarker1);
  padding: calc(0.3 * var(--s)) calc(1 * var(--s));
  color: var(--colorBackground);
}
.btnDelete:hover {
  background-color: var(--colorWarning);
}

/* List of past forwarding information */

.pastPair {
  box-sizing: border-box;

  display: grid;
  width: 100%;
  grid-template-areas: 'pastNetwork    pastAddress';
  grid-template-columns: 30% 68%;
  grid-template-rows: auto;
  margin: calc(0.5 * var(--s)) 0;
  margin-top: calc(0.5 * var(--s));
  grid-column-gap: 2%;
  align-items: center;
  opacity: 0.5;
}
.pastPair:hover {
  background-color: var(--colorDarker1);
  cursor: pointer;
}

.pastNetwork {
  grid-area: pastNetwork;
  width: 100%;
  box-sizing: border-box;
  padding: calc(0.5 * var(--s)) calc(1 * var(--s));

  justify-self: right;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pastAddress {
  width: 100%;
  grid-area: pastAddress;
  box-sizing: border-box;
  padding: calc(0.5 * var(--s)) calc(1 * var(--s));
  justify-self: left;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.changes .bytesLeft,
.changes .bytesOver {
  display: block;
  width: 100%;
  /* margin: calc(1 * var(--s)) 0; */
}
.bytesLeft {
  color: var(--colorDarker4);
}
.bytesOver {
  color: var(--colorWarning);
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .wrapper {
    grid-template-areas:
      'editor       title'
      'editor       subtitle'
      'editor       buttonWrapper'
      'pastList     changes'
      'pastList     .'
      'pastList     .'
      'pastList     .';
    grid-template-columns: 50% 45%;
    grid-template-rows: auto auto auto auto auto auto;

    grid-gap: 0% 5%;
    margin-top: calc(-5 * var(--s));
  }
  .pastList {
    align-self: flex-start;
  }
  .title {
    align-self: flex-start;
    justify-self: right;
    margin-bottom: calc(4 * var(--s));
    margin-top: calc(1 * var(--s));
    text-align: right;
  }
  .subtitle {
    margin-right: 0;
  }
  .changes {
    padding-top: 0;
    justify-self: right;
    text-align: right;
  }
  .buttonWrapper {
    justify-self: right;
    text-align: right;
    padding-bottom: 0;
  }
  .editor {
    justify-self: right;
    align-self: flex-end;
    text-align: right;
  }
}
