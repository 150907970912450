.wrapper {
  box-sizing: border-box;

  margin: 0 calc(2 * var(--s));

  font-size: calc(2.5 * var(--s));
  color: var(--colorBitcoinBlue);
  display: grid;

  grid-template-columns: calc(50% - (2 * var(--s))) calc(50% - (2 * var(--s)));
  grid-template-rows: auto auto auto auto auto auto;

  grid-gap: calc(1 * var(--s)) calc(2 * var(--s));

  grid-template-areas:
    'title            title'
    'fees             fees'
    'totalCost        totalCost'
    'status           status'
    'buttonWrapper    buttonWrapper'
    'txSummary        txSummary';

  align-items: center;
  margin-bottom: calc(2.5 * var(--s));
}

.title {
  grid-area: title;
  width: 100%;
  margin-bottom: calc(7 * var(--s));
}

.title {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  font-size: calc(4 * var(--s));
  margin-bottom: calc(3 * var(--s));
  cursor: default;
  color: var(--colorBitcoinBlue);
  text-align: right;
  max-width: calc(50 * var(--s));
  padding-right: calc(6 * var(--s));
  margin-top: calc(-6 * var(--s));
  margin-right: calc(-1.5 * var(--s));
  margin-left: auto;
}

.title::after {
  box-sizing: border-box;

  content: '';
  --offset: calc(0.75 * var(--s));
  top: var(--offset);
  right: calc(2 * var(--s));
  height: calc(100% - (2.1 * var(--offset)));
  position: absolute;
  border-right: solid calc(2 * var(--s)) var(--colorBitcoinBlue);
}

.fees {
  grid-area: fees;
  width: 100%;
  padding: calc(2.1 * var(--s)) 0;
  position: relative;
}

.txSummary {
  box-sizing: border-box;

  grid-area: txSummary;
  width: 100%;
  padding: calc(2 * var(--s)) 0;
  white-space: pre-wrap;
}

.status {
  grid-area: status;
  width: 100%;
}

.totalCost {
  grid-area: totalCost;
  box-sizing: border-box;
  padding: calc(2 * var(--s)) calc(1 * var(--s));
  width: 100%;
}

.buttonWrapper {
  grid-area: buttonWrapper;
  width: 100%;
  padding: calc(2 * var(--s)) 0;
}

.status__button {
  display: inline-block;
  background-color: var(--colorHighlightDark);
  color: var(--colorBackground);
  border-radius: calc(0.5 * var(--s));
  margin: calc(1 * var(--s));
  padding: calc(0.75 * var(--s)) calc(2 * var(--s));
}
.status__button:hover {
  background-color: var(--colorBitcoinBlue);
}

.status__failed {
  margin: calc(1 * var(--s));
  padding: calc(0.75 * var(--s)) calc(2 * var(--s));
  color: var(--colorWarning);
}

.txSummary > div {
  font-size: calc(2.5 * var(--s));
}

.txSummary table {
  box-sizing: border-box;
  width: 100%;
  vertical-align: top;
  border-collapse: collapse;
}

.txSummary td {
  font-size: calc(2.2 * var(--s));
  /* line-height: calc(2.2 * var(--s)); */
  font-weight: normal;
}

.txSummary table tr {
  vertical-align: top;
  border: calc(2.5 * var(--s)) solid transparent;
}

.txSummary table td p {
  display: block;
  box-sizing: border-box;
  margin-bottom: calc(0.5 * var(--s));
  padding: 0;
  --offset: calc(2 * var(--s));
  text-indent: calc(-1 * var(--offset));
  padding-left: var(--offset);
}

.txSummary table tr:first-child {
  border-top: 0 solid transparent;
}

.txSummary table td:first-child {
  border-right: calc(3 * var(--s)) solid transparent;
}

.txSummary table td:nth-child(1) {
  text-align: right;
  width: calc(12 * var(--s));
  /* padding-top: calc(0.5 * var(--s)); */
}

.txSummary table td:nth-child(2) {
  text-align: left;
  opacity: 0.7;
}

.balance {
  box-sizing: border-box;
  display: inline-block;
  background-color: var(--colorDarker1);
  border-radius: calc(1 * var(--s));
  padding: calc(0.25 * var(--s)) calc(1 * var(--s));
  /* vertical-align: middle; */
  text-indent: 0;
  /* margin: calc(0.25 * var(--s)) 0; */
}

.error {
  color: var(--colorWarning);
}

.notify {
  color: var(--colorHighlightDark);
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .wrapper {
    grid-template-columns: auto calc(2 * var(--s)) calc(40% - (2 * var(--s)));
    grid-template-rows: auto auto auto auto auto;

    grid-template-areas:
      'title          title title'
      'fees           .     totalCost'
      'txSummary      .     buttonWrapper'
      'txSummary      .     status'
      'txSummary      .     .';
    align-items: flex-start;
    text-align: right;
  }

  .title {
    /* margin-top: calc(-4 * var(--s)) */
  }

  .buttonWrapper {
    justify-self: right;
  }
  .totalCost {
    justify-self: right;
  }
  .txSummary > div {
    padding: calc(1 * var(--s)) calc(0 * var(--s));
  }
}
