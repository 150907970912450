.wrapper {
  padding: calc(1 * var(--s));
  display: inline-block;
  box-sizing: border-box;

  --inputSize: calc(2.3 * var(--s));
  font-size: var(--inputSize);
  color: var(--colorBitcoinBlue);
  text-align: center;
}

.shiftRight {
  box-sizing: border-box;

  width: 100%;
  padding-left: var(--inputSize);
}

.label {
  box-sizing: border-box;
  padding-bottom: calc(1 * var(--s));
  text-indent: calc(-1 * var(--inputSize));

  font-size: var(--inputSize);
  /* position: absolute; */
  display: block;
  opacity: 0.7;
  text-align: left;

  /* so next line character works */
  white-space: pre-wrap;
}

.choiceArea {
  display: inline-flex;
  background-color: var(--colorBrighter8);
  border-radius: calc(0.5 * var(--s));
  color: var(--colorMediumText);
  opacity: 1;
}

.choice {
  position: relative;
  cursor: pointer;
  padding: calc(0.75 * var(--s)) calc(2 * var(--s));
  margin: calc(0.5 * var(--s));
  border-radius: calc(0.5 * var(--s));
  /* -webkit-background-clip: text; */
}

.selected {
  background-image: linear-gradient(
    135deg,
    var(--colorGradientTopLeft) -300%,
    var(--colorGradientBottomRight) 400%
  );
  color: var(--colorBitcoinBlue);
}

.choice:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: calc(0.5 * var(--s));

  background-color: var(--colorDarker0_5);
}
