.wrapper {
  box-sizing: border-box;
  margin: 0 2%;
  font-size: calc(2.5 * var(--s));
  color: var(--colorBitcoinBlue);

  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: auto auto auto auto auto;
  grid-gap: calc(2.5 * var(--s)) 2%;
  grid-template-areas:
    'title                      title'
    'availableActions           availableActions'
    '.                          .   '
    'unavailableActions         unavailableActions'
    'buttonWrapper              buttonWrapper';

  align-items: center;
}

.title {
  grid-area: title;

  font-size: calc(3 * var(--s));
  margin-bottom: calc(2 * var(--s));
}

.availableActions {
  box-sizing: border-box;

  grid-area: availableActions;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-items: flex-end;

  text-align: right;
}

.inputForms {
  width: 100%;
  padding: calc(1 * var(--s));
  padding-bottom: calc(2 * var(--s));
  padding-top: calc(1 * var(--s));

  border-radius: calc(0.5 * var(--s));
}

.okButton {
  margin-top: calc(2 * var(--s));
  margin-bottom: calc(2 * var(--s));
}

.availableActions > div {
  margin: calc(2 * var(--s));
  margin-top: 0;
}

.unavailableActions {
  grid-area: unavailableActions;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;

  overflow-wrap: normal;
  word-wrap: normal;
  width: 100%;
  overflow-x: hidden;
}

.buttonWrapper {
  grid-area: buttonWrapper;
}
.buttonWrapper > div {
  margin: calc(2 * var(--s));
}

.title {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  font-size: calc(4 * var(--s));
  /* margin: calc(3 * var(--s)); */
  margin-bottom: calc(3 * var(--s));
  cursor: default;
  color: var(--colorBitcoinBlue);
  text-align: right;
  max-width: calc(50 * var(--s));
  padding-right: calc(6 * var(--s));
  margin-top: calc(-6 * var(--s));
  margin-left: auto;
}

.title::after {
  box-sizing: border-box;

  content: '';
  --offset: calc(0.75 * var(--s));
  top: var(--offset);
  right: calc(2.5 * var(--s));
  height: calc(100% - (2.1 * var(--offset)));
  position: absolute;
  border-right: solid calc(2 * var(--s)) var(--colorBitcoinBlue);
}

.orange {
  color: var(--colorBitcoinOrange);
  text-shadow: none;
}

.unavailableActions__title {
  margin-bottom: calc(1 * var(--s));
  overflow-wrap: normal;
  word-wrap: normal;
  /* width: 100%; */
}
.unavailableActions__actionList {
  opacity: 0.8;
}
.unavailableActions__actionList__action {
  box-sizing: border-box;
  margin: calc(1 * var(--s)) 0;
  padding-left: calc(1 * var(--s));
  overflow-wrap: normal;
  word-wrap: normal;
  /* width: 100%; */
}
.unavailableActions__actionList__action__title {
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--colorDarker1);
  margin-bottom: calc(1 * var(--s));
  border-radius: calc(0.5 * var(--s));
  padding: calc(0.5 * var(--s)) calc(1 * var(--s));
  overflow-wrap: normal;
  word-wrap: normal;
  /* width: 100%; */
}
.unavailableActions__actionList__action__permissionList {
  box-sizing: border-box;
  padding-left: calc(1 * var(--s));
  padding-bottom: calc(1 * var(--s));
  overflow-x: hidden;
  width: 100%;
}
.unavailableActions__actionList__action__permissionList__permission {
  box-sizing: border-box;
  overflow-wrap: normal;
  word-wrap: normal;
  width: 100%;
  padding-left: calc(1 * var(--s));
  word-break: normal;
  overflow-wrap: normal;
  font-size: calc(2 * var(--s));
  color: var(--colorWarning);
}

.breakable {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

@media screen and (min-aspect-ratio: 14/10) {
  .wrapper {
    /* grid-template-columns:  auto calc(60 * var(--s)); */
    grid-template-columns: 39% 59%;
    grid-template-rows: auto auto auto auto auto;

    grid-template-areas:
      '.                          title'
      'unavailableActions         availableActions'
      'unavailableActions         availableActions   '
      'unavailableActions         buttonWrapper'
      'unavailableActions         .';
  }
  .title {
    text-align: right;
    /* margin-right: calc(2 * var(--s)); */
  }
  .availableActions {
    align-self: flex-start;
  }

  .unavailableActions {
    align-self: flex-start;
  }

  .inputForms {
    width: 100%;
  }
}
