.lblMainTitle {
  display: block;
  font-size: calc(5 * var(--s));
  color: var(--colorBitcoinBlue);
  white-space: nowrap;
}
.lblMainTitle span {
  color: var(--colorBitcoinOrange);
}

.results {
  /* padding-top: calc(2 * var(--s)); */
  /* height: 100%; */
}

.divSearch {
  display: inline-block;
  width: 100%;
}

.txtSearch {
  color: var(--colorBitcoinOrange);
  width: 60vw;
  caret-color: var(--colorBitcoinOrange);
}

.txtSearch:placeholder-shown {
  caret-color: rgba(255, 166, 0, 0.13);
}

.txtSearch::placeholder {
  color: var(--colorBitcoinOrange);
}

/* -------------------------------------------------------------------------- */
/*                           @media & changes below only                      */
/* -------------------------------------------------------------------------- */

.lblMainTitle {
  margin-top: var(--bnsTitleTopMargin);
  cursor: pointer;
}

/* after search */
.lblMainTitleAfter {
  margin-top: var(--bnsTitleTopMarginAfter);
}

.divSearch {
  margin-top: var(--searchBarTopMargin);
}

/* after search */
.divSearchAfter {
  margin-top: var(--searchBarTopMarginAfter);
}

.wrapper {
  box-sizing: border-box;
  transform-origin: top left;

  /* fixed makes children with fixed work better */
  position: fixed;

  transform: scale(var(--verticalScale));
  width: 100%;
  height: calc(var(--dh));
}

.lblMainTitle,
.divSearch {
  box-sizing: border-box;

  transform-origin: top;
  transform: scale(var(--verticalScale));
  --correction: 1.1;
}

@media screen and (min-aspect-ratio: 14/10) {
  .lblMainTitle:not(.lblMainTitleAfter) {
    margin-top: calc(var(--bnsTitleTopMargin) / var(--horizontalScale));
  }

  .wrapper {
    transform: scale(var(--horizontalScale));
    width: calc(100% / var(--horizontalScale));
    height: calc((var(--dh) / var(--horizontalScale)));
  }

  /* search bar & title could use a bit less scaling  */
  .divSearch,
  .lblMainTitle {
    transform: scale(var(--correction));
  }

  .txtSearch {
    width: calc(60 * var(--s));
  }
}
