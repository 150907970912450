.wrapper {
  box-sizing: border-box;
  color: var(--colorBitcoinBlue);
  font-size: calc(2.5 * var(--s));

  margin: 0 calc(0.1 * var(--s));
  overflow-y: scroll;
  overflow-x: hidden;
  /* height: calc(var(--dh) - (6 * var(--s))); */
  height: 100%;
}

.title {
  font-size: calc(3 * var(--s));
  margin: calc(2 * var(--s));
  margin-top: calc(4 * var(--s));
  margin-bottom: calc(2 * var(--s));
}

.contentWrapper {
  box-sizing: border-box;

  text-align: center;
  padding: calc(0.3 * var(--s)) calc(2 * var(--s));
}

.contentWrapper p {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: calc(2 * var(--s));
  line-height: calc(3.3 * var(--s));

  --offset: calc(2 * var(--s));
  text-indent: calc(-1 * var(--offset));

  padding: 0;
  padding-left: var(--offset);

  width: 66vw;
  text-align: left;
}

.title span,
.contentWrapper p span {
  color: var(--colorBitcoinOrange);
  font-weight: bold;
}

.title div {
  display: inline-block;
}

.drawing {
  display: block;

  width: 90vw;
  max-width: calc(70 * var(--s));

  margin: 0 auto;
  margin-bottom: calc(2 * var(--s));
}

.contentWrapper a {
  box-sizing: border-box;
  display: inline-block;
  margin: calc(0.5 * var(--s)) auto;

  color: var(--colorLightText);
  /* margin-left: calc(1 * var(--s)); */
  padding: calc(0.5 * var(--s)) calc(2 * var(--s));
  border-radius: calc(0.5 * var(--s));
  text-indent: 0;
}

.contentWrapper a:hover {
  background-color: var(--colorDarker0_5);
}

/* unused */
.wrapper pre {
  font-size: calc(1.5 * var(--s));
  text-align: left;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  word-break: break-all;

  user-select: text;
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .contentWrapper {
    transform-origin: top;
    transform: scale(var(--horizontalScale));
  }
}
