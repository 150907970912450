/* navigated to content */
.content {
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0; */
  width: 100%;

  /* makes room for nav bar */
  bottom: var(--navbarHeight);
  box-sizing: border-box;
  padding: 0.2% 0.2%;

  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .content {
    /* makes room for nav bar */
    bottom: calc(var(--navbarHeight) * var(--horizontalScale));
  }
}
