.wrapper {
  font-size: (2 * var(--s));
  position: relative;
  height: 100%;
  width: 100%;
}

.scrollbars {
  transform: scale(var(--verticalScale));

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;

  margin: 0 calc(2 * var(--s));
  left: 0;
  right: 0;

  /*
  1. percentage units only had working scroll with position fixed
  percentage units var(--dh) was introduced for mobile height
  that is different from vh due to pop up bars.
  2. when parent has transform scale applied, coordinate system
  becomes warped and makes it ~impossible to resize.
  Have to make sure no transforms on parents of scrolls.
  */
  position: fixed;
  /* top: 100px; */

  bottom: var(--navbarHeight);
  top: var(--searchResultsTop);

  color: var(--colorDarkText);

  padding: 0 calc(2 * var(--s));

  overflow-x: hidden;
  overflow-y: scroll;
}

.breakable {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.listContainer {
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: calc(1 * var(--s));
}

.listItem {
  box-sizing: border-box;
  font-size: calc(2.9 * var(--s));
  color: var(--colorBackground);
  background-color: var(--colorBitcoinBlue);
  border-radius: calc(0.5 * var(--s));
  padding: calc(0.75 * var(--s)) calc(2 * var(--s));
  margin: calc(1 * var(--s)) calc(2 * var(--s));
  cursor: pointer;
  height: auto;

  padding-left: calc(4 * var(--s));
  text-indent: calc(-2 * var(--s));
}

.listItem .key {
  color: var(--colorBitcoinOrange);
}

.listItem,
.listItem .key,
.listItem span,
.listItem div {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.wrapper .listItem:hover {
  background-color: var(--colorBitcoinOrange);
}

.wrapper .listItem:hover .key {
  color: var(--colorBitcoinBlue);
}

.wrapper .listItem:hover:active {
  transform: translateY(calc(0.2 * var(--s)));
}

.wrapper::-webkit-scrollbar {
  /* chrome scroll bar */
  background: var(--colorTransparent);
  height: calc(0.5 * var(--s));
  width: calc(1 * var(--s));
}

.wrapper::-webkit-scrollbar-track {
  /* chrome scroll bar background */
  background: var(--colorTransparent);
}

.wrapper::-webkit-scrollbar-thumb {
  /* scroll bar itself */
  background-color: var(--colorDarker1);
  border-radius: calc(0.5 * var(--s));
  box-shadow: inset 0 0 0 calc(0.1 * var(--s)) var(--colorTransparent);
}

.expired {
  display: inline-block;
  color: var(--colorWarning);
}

.avaiability {
  width: 100%;
  text-align: center;
  margin-top: calc(1 * var(--s));
}

.createLink {
  display: inline-block;
  text-align: center;
  opacity: 1;
  background-color: var(--colorBitcoinOrange);
  color: var(--colorBackground);
  width: calc(40 * var(--s));
  font-size: calc(3 * var(--s));
  padding: calc(0.6 * var(--s)) calc(3 * var(--s));
  margin: calc(0.5 * var(--s));
  border-radius: calc(0.5 * var(--s));
  justify-self: center;
  align-self: center;
}

.createLink:hover {
  background-color: var(--colorBitcoinBlue);
  color: var(--colorBackground);
}

.describe {
  color: var(--colorLightText);
  text-align: left;
  flex-basis: 100%;
  box-sizing: border-box;
}

.describe > div {
  display: block;
}

.ownershipDetails {
  text-align: center;
  margin-bottom: calc(1 * var(--s));
  /* box-sizing: border-box; */
  text-align: left;
  /* padding: calc(1 * var(--s)) 0; */
}

/* allow details to be selectable */
.ownershipDetails span,
.ownershipDetails p {
  -webkit-user-select: text;
  -moz-user-select: -moz-text;
  -ms-user-select: text;
  user-select: text;
}

.balance {
  box-sizing: border-box;
  display: inline-block;
  background-color: var(--colorDarker1);
  border-radius: calc(1 * var(--s));
  padding: calc(0.25 * var(--s)) calc(1 * var(--s));
  text-indent: 0;
}

.linkable {
  cursor: pointer;
}

.matches {
  box-sizing: border-box;
  color: var(--colorLightText);
  text-align: left;
  padding: calc(2 * var(--s)) calc(4 * var(--s));
  width: 100%;
}

.link {
  color: var(--colorLightText);
  background-color: var(--colorTransparent);
  margin: calc(2 * var(--s));
  margin-left: auto;
  border-radius: calc(0.5 * var(--s));
  padding: calc(0.5 * var(--s)) calc(2 * var(--s));
  cursor: pointer;
}

.link:hover {
  background-color: var(--colorLightText);
  color: var(--colorBackground);
}

.link span:last-child {
  display: none;
}

.link:active > span {
  display: inline-block;
}

/* -------------------------------------------------------------------------- */
/*                                   @media                                   */
/* -------------------------------------------------------------------------- */

@media screen and (min-aspect-ratio: 14/10) {
  .listContainer {
    justify-content: space-around;
  }
}
