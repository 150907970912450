.logo_wrapper {
  line-height: 0;
}

/* ------------------------------ Bitcoin icon ------------------------------ */

.logo {
  --btcLogoInputSize: var(--wallet__logo_size);

  text-rendering: geometricPrecision; /* firefox improvement */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.2;
  filter: saturate(0.1);
  transform: translateY(calc(0 * var(--s))) scale(0.7);
  transform-origin: bottom left;
}

.logo:hover {
  cursor: pointer;
  opacity: 0.9;
  filter: saturate(1);
  transform: translate(0, calc(0 * var(--s))) scale(0.7);
}

.logo:active {
  /* transform-origin: center; */
  transform: translate(1%, calc(0.1 * var(--s))) scale(0.68);
}

.logo_selected {
  opacity: 0.9;
  filter: saturate(1);
}

/* -------------- transparent background to turn off interface -------------- */

.interface_not {
  background-color: var(--colorDarker4);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
}

/* -------------------------------------------------------------------------- */
/*                                  interface                                 */
/* -------------------------------------------------------------------------- */

.interface {
  box-sizing: border-box;
  bottom: var(--wallet__logo_size);
  left: 2.5vmin;
  padding: 5vmin;
  width: 95vmin;
  height: auto;
  position: fixed;
  background-color: var(--colorHoverWallet);
  color: var(--colorBitcoinBlue);
}

/* title message on pop up interface */
.title {
  box-sizing: border-box;
  font-size: calc(4 * var(--s));
  padding-bottom: calc(6 * var(--s));
  color: var(--colorBitcoinBlue);
  font-weight: bold;
}

/* bitcoin value in output */
.amounts {
  width: 100%;
  padding-top: calc(2 * var(--s));
  padding-bottom: calc(2 * var(--s));
}

.calculatedAmounts {
  box-sizing: border-box;
  text-align: left;
  text-indent: calc(-2 * var(--s));
  padding-left: calc(2 * var(--s));
  color: var(--colorMediumText);
}

.lastError {
  padding: calc(1 * var(--s));
  color: var(--colorLightText);
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding-top: calc(2 * var(--s));
}

/* --------------------------------- history -------------------------------- */

.entries {
  text-align: left;
}

.entries__entry {
  padding-bottom: calc(3 * var(--s));
}

.entries__entry__time {
  padding-left: calc(2 * var(--s));
  color: var(--colorLightText);
}

.entries__entry__button {
  padding-left: calc(2 * var(--s));
}

.entries__entry__message {
  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal;
  padding-left: calc(2 * var(--s));
  color: var(--colorMediumText);
}

.entries__entry__error {
  color: var(--colorWarning);
}

.entries__entry__done {
  color: var(--colorBitcoinBlue);
}
