/* Global CSS settings */

/*
<homecontent>
  <.lblMainTitle (+ .lblMainTitleAfter)
    33vh (10vh) margin top
    5s font size (horizontal 1.1x correction)
  />
  <.divSearch (+.divSearchAfter)
  3vh (2vh) margin top
  2.5s font size textbox (horizontal 1.1x correction)
  />
  <.results>
  <SearchResults>
    <.matches
      2s padding
      2s fontsize
    >


*/

/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */

/* ubuntu-300 - latin */
/* font-family: 'Ubuntu'; */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.eot');
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.woff2') format('woff2'),
    url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.woff') format('woff'),
    url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.ttf') format('truetype'),
    url('./../assets/fonts/Ubuntu/ubuntu-v14-latin-300.svg#Ubuntu')
      format('svg');
}

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./../assets/fonts/Monstserrat/montserrat-v14-latin-300.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* defaults rset */
body,
html,
table,
i,
p,
tr,
td,
th,
a,
span,
div,
aside {
  /* not selectable by default */
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  /* remove default styling */
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-family: 'Montserrat';

  /* -------------------------------------------------------------------------- */
  /*                                Global sizes                                */
  /* -------------------------------------------------------------------------- */

  /* base size of all things */
  --s: calc(1 * (0.5vw + 0.5vh));
  /* multiplier for transform scale on horizontal orientation */
  --horizontalScale: 0.7;
  /* multiplier for transform scale on vertical orientation */
  --verticalScale: 0.999;
  /* to track when horizontal */
  --currentScale: var(--verticalScale);

  /* lblMainTitle margin top */
  --bnsTitleTopMargin: calc(33vh - calc(7 * var(--s)));
  --bnsTitleTopMarginAfter: 10vh;
  /* searchbar margin top (3vh/2vh before) */
  --searchBarTopMargin: calc(3 * var(--s));
  --searchBarTopMarginAfter: calc(2 * var(--s));
  /* search results top */
  --searchResultsTop: calc(var(--bnsTitleTopMarginAfter) + (23.5 * var(--s)));

  /*
    On create pages,
    amount of offset from vertical bar to scroll bar and content
  */
  --headerOffset: calc(3 * var(--s));
  /* padding for white space at top under header ( with scrollbar) */
  --headerWhitespace: calc(18 * var(--s));
  /* navbar height */
  --navbarHeight: calc(6.1 * var(--s));
  /* bitcion icon height */
  --wallet__logo_size: calc((10 * var(--s)) + 1px);

  /*
    display height
    height: 100vh on mobile does not account for
    appearing/disappearing bars (good for fonts)
    but actual visible height is necessary for keeping things visible
    and not being able to scroll outside the page.
    https://bugs.chromium.org/p/chromium/issues/detail?id=844848#c4
    This still does not work on brave browser - content overflows.
    Chrome & firefox on mobile are good.
  */
  --dh: 100%;

  /*
    var(--dh2) can be set for height via javascript
    https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    For now not sure it's worth it.
  */

  /* -------------------------------------------------------------------------- */
  /*                               global colors                                */
  /* -------------------------------------------------------------------------- */

  --colorBitcoinBlue: rgb(17, 17, 51);
  --colorBitcoinOrange: rgb(255, 155, 0);

  /* derived from tetradic rgb colors with blue/orange as base */
  --colorHighlightDark: rgba(47, 121, 231, 0.75);
  --colorHighlightLight: #ffff00;

  /* transparent version of blue */
  --colorLightText: rgba(17, 17, 51, 0.35);
  --colorMediumText: rgba(17, 17, 51, 0.5);
  --colorHeavyText: rgba(17, 17, 51, 0.8);
  --colorDarkText: var(----colorHeavyText);

  --colorButtonText: white;
  --colorBackground: white;
  --colorTransparent: rgba(0, 0, 0, 0);
  --colorBrighter1: rgba(255, 255, 255, 0.1);
  --colorBrighter2: rgba(255, 255, 255, 0.2);
  --colorBrighter3: rgba(255, 255, 255, 0.3);
  --colorBrighter4: rgba(255, 255, 255, 0.4);
  --colorBrighter5: rgba(255, 255, 255, 0.5);

  --colorBrighter8: rgba(255, 255, 255, 0.8);
  --colorBrighter9: rgba(255, 255, 255, 0.9);

  /* use blue tint */
  --colorDarker0_5: rgba(17, 17, 51, 0.05);
  --colorDarker1: rgba(17, 17, 51, 0.1);
  --colorDarker2: rgba(17, 17, 51, 0.2);
  --colorDarker4: rgba(17, 17, 51, 0.4);
  --colorDarker5: rgba(17, 17, 51, 0.5);
  --colorDarker6: rgba(17, 17, 51, 0.6);

  --colorDarker8: rgba(17, 17, 51, 0.8);
  --colorDisabled: rgba(17, 17, 51, 0.5);

  --colorWarning: rgba(180, 12, 12, 0.685);

  --colorGradientTopLeft: rgba(45, 154, 255, 0.15);
  --colorGradientBottomRight: rgba(212, 145, 37, 0.07);

  --colorHoverWallet: #f1f1f5;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  width: 100%;
  height: var(--dh);

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar,
#root::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

body {
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */

  overflow-y: hidden;
  overflow-x: hidden;
  font-size: calc(2.2 * var(--s));
  color: var(--colorBitcoinBlue);
  text-align: center;

  /* fallback */
  background-color: var(--colorBackground);

  /* consistent gradients */
  background-image: linear-gradient(
    135deg,
    var(--colorGradientTopLeft) 0%,
    var(--colorGradientBottomRight) 100%
  );

  background-image: -moz-linear-gradient(
    -45deg,
    var(--colorGradientTopLeft) 0%,
    var(--colorGradientBottomRight) 100%
  );
  background-image: -o-linear-gradient(
    -45deg,
    var(--colorGradientTopLeft) 0%,
    var(--colorGradientBottomRight) 100%
  );
  background-image: -ms-linear-gradient(
    -45deg,
    var(--colorGradientTopLeft) 0%,
    var(--colorGradientBottomRight) 100%
  );
  background-image: -webkit-linear-gradient(
    -45deg,
    var(--colorGradientTopLeft) 0%,
    var(--colorGradientBottomRight) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, var(--colorGradientTopLeft)),
    color-stop(100%, var(--colorGradientBottomRight))
  );

  /* font and rendering improvement options */
  text-rendering: geometricPrecision; /* firefox improvement */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-shadow: var(--colorDarker1) 0px 0.3px 0.3px; */
  /* -webkit-text-stroke: 0.2px; */
  /* transform: scale(0.9999); */
}

a {
  text-decoration: none;
  color: var(--colorLightText);
  vertical-align: middle;

  -webkit-user-drag: none;
  -ms-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
}

a:hover,
.canHover:hover {
  background-color: var(--colorDarker0_5);
}

.canPress:hover {
  cursor: pointer;
}

a:active,
.canPress:active {
  transform: translateY(calc(0.1 * var(--s)));
}

/* mark selectable where necessary */
.selectable {
  -webkit-user-select: text;
  -moz-user-select: -moz-text;
  -ms-user-select: text;
  user-select: text;
}

textarea {
  box-sizing: border-box;
  font-family: 'Montserrat';
  text-align: center;
  resize: none;
  width: 100%;

  color: var(--colorBitcoinOrange);

  background-color: var(--colorBackground);

  font-size: calc(2.5 * var(--s));

  height: calc(4.5 * var(--s));
  line-height: calc(2.5 * var(--s));

  padding: calc(0.5 * var(--s)) calc(1 * var(--s));
  padding-bottom: 0;

  border-radius: calc(0.5 * var(--s));

  border: calc(0 * var(--s)) solid var(--colorBackground);
  border-top: calc(0.4 * var(--s)) solid var(--colorBackground);
  border-left: calc(1 * var(--s)) solid var(--colorBackground);
  border-right: calc(1 * var(--s)) solid var(--colorBackground);

  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  cursor: default;
  vertical-align: middle;

  /* firefox scrollbar */
  scrollbar-color: var(--colorDarker2) var(--colorTransparent);
  scrollbar-width: thin;

  /* always selectable */
  -webkit-user-select: text;
  -moz-user-select: -moz-text;
  -ms-user-select: text;
  user-select: text;

  /* edge lol */
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

textarea::placeholder {
  color: var(--colorBitcoinOrange);
  opacity: 0.5;
}

/* firefox has pixel based scrollbar and covers up text with viewport sized borders */
@supports (-moz-appearance: none) {
  textarea {
    border-top: calc(0 * var(--s)) solid var(--colorBackground);
  }
}

textarea::-webkit-scrollbar {
  /* chrome scroll bar */
  background: var(--colorTransparent);
  height: calc(1 * var(--s));
  width: calc(1 * var(--s));
}

textarea::-webkit-scrollbar-track {
  /* chrome scroll bar background */
  background: var(--colorTransparent);
}

textarea::-webkit-scrollbar-thumb {
  /* scroll bar itself */
  background-color: var(--colorDarker1);
  border-radius: calc(1 * var(--s));
  box-shadow: inset 0 0 0 calc(0.1 * var(--s)) var(--colorBackground);
}

textarea:focus {
  outline: none;
}

.scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;

  /* firefox */
  scrollbar-color: var(--colorDarker1) var(--colorTransparent);
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  /* chrome scroll bar */
  background: var(--colorTransparent);
  height: calc(0.5 * var(--s));
  width: calc(0.5 * var(--s));
}

.scrollbar::-webkit-scrollbar-track {
  /* chrome scroll bar background */
  background: var(--colorTransparent);
}

.scrollbar::-webkit-scrollbar-thumb {
  /* scroll bar itself */
  background-color: var(--colorDarker1);
  border-radius: calc(0.5 * var(--s));
  box-shadow: inset 0 0 0 calc(0.1 * var(--s)) var(--colorTransparent);
}

.btnCircle {
  position: static;
  align-self: center;
  display: inline-block;
  font-size: calc(4.1 * var(--s));
  width: calc(3 * var(--s));
  height: calc(3 * var(--s));
  line-height: calc(3 * var(--s));
  margin: 0;
  padding: calc(0.25 * var(--s));
  border-radius: calc(3 * var(--s));
  background-color: var(--colorBitcoinBlue);
  cursor: pointer;
  color: var(--colorBackground);
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-items: center;
}
.btnCircle span {
  display: block;
  position: static;
  width: 100%;
  height: 100%;
}
.btnCircle:hover {
  background-color: var(--colorBitcoinOrange);
}

/* tooltips */
/* add content for tooltips inside <aside> tags inside element */

.addToolTip:hover {
  transform: translateY(calc(0.02 * var(--s)));
}

.addTooltip aside {
  /* display: none; */
  /* box-sizing: border-box; */
  font-size: calc(2 * var(--s));
  content: '';
  visibility: hidden;
  width: calc(12 * var(--s));
  background-color: var(--colorBitcoinBlue);
  color: var(--colorBackground);
  text-align: center;
  border-radius: calc(0.6 * var(--s));
  border: 0 solid transparent;
  padding: calc(0.5 * var(--s)) 0;
  position: absolute;
  z-index: 11;
  top: 150%;
  left: 20%;
  margin-left: calc(-6 * var(--s));
  /* opacity: 0; */
  /* transition: opacity ease-in-out  0.15s ; */
}

.addTooltip aside::after {
  content: '';
  position: absolute;
  bottom: calc(97%);
  left: 63%;
  margin-left: calc(-1 * var(--s));
  border: calc(1 * var(--s)) solid transparent;
  border-bottom: calc(1.5 * var(--s)) solid transparent;
  border-style: solid;
  border-color: transparent transparent var(--colorBitcoinBlue) transparent;
}

/* to right version  */
.addTooltipRight aside {
  box-sizing: border-box;
  display: inline-block;
  font-size: calc(2 * var(--s));
  content: '';
  visibility: hidden;
  width: calc(20 * var(--s));
  background-color: var(--colorBitcoinBlue);
  color: var(--colorBackground);
  text-align: center;
  border-radius: calc(0.6 * var(--s));
  border: 0 solid transparent;
  /* padding: calc(0.7 * var(--s)) calc(1 * var(--s)); */
  padding: calc(1 * var(--s));
  position: absolute;
  z-index: 11;
  left: 150%;
  top: -50%;
}
.addTooltipRight aside::after {
  content: '';
  position: absolute;
  top: 50%;
  right: calc(100% - 0.1 * var(--s));
  margin-top: calc(-0.7 * var(--s));
  border: calc(0.7 * var(--s)) solid transparent;
  border-right: calc(1 * var(--s)) solid transparent;
  border-style: solid;
  border-color: transparent var(--colorBitcoinBlue) transparent transparent;
}

.addTooltipRight:hover aside,
.addTooltip:hover aside {
  /* display: inline-block; */
  visibility: visible;
}

/* remove native p styling */
p {
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

table {
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  table-layout: fixed;
}

table,
tr,
td,
th {
  font-family: 'Montserrat';
  font-size: calc(2 * var(--s));
  font-weight: normal;
}

td {
  box-sizing: border-box;
  vertical-align: top;
  border: calc(2 * var(--s)) solid transparent;
}

/* first row no border */
tr:nth-child(1) td {
  border-top: 0 solid transparent;
}

/* first column right align */
td:nth-child(1),
th:nth-child(1) {
  text-align: right;
  width: 33%;
  font-weight: bold;
}

/* 2nd column left align */
td:nth-child(2),
th:nth-child(2) {
  width: 66%;
  text-align: left;
  opacity: 0.8;
}

/* how to show p inside td inside table */
table td p {
  display: block;
  box-sizing: border-box;
  margin-bottom: calc(0.5 * var(--s));
  padding: 0;
  --offset: calc(2 * var(--s));
  text-indent: calc(-1 * var(--offset));
  padding-left: var(--offset);
}

/* stops animations on resize */
.resize-animation-stopper *,
.resize-animation-stopper :before,
.resize-animation-stopper :after {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: color 0 ease-in !important;
  -ms-transition: none !important;
  transition: none !important;
  animation: none !important;
  transition-property: none !important;
}

.letter_breakable {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.word_breakable {
  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal;
}

.arbitraryFixes {
  /* various animation fixes */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-filter: blur(0);
  filter: blur(0);
  image-rendering: optimizeQuality; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
  font-smoothing: antialiased !important;

  /* this fixes svg text in firefox!!! */
  stroke: transparent;

  /* text  hacks */
  /* text-shadow: var(--colorDarker1) 0px 1px 1px; */
  /* -webkit-text-stroke: 1px; */

  /* wobbly css */
  /* width: 100%; */
  /* display: table; */
}

.dropshadow {
  box-shadow: calc(1 * var(--s)) calc(1 * var(--s)) calc(3 * var(--s))
    calc(-1 * var(--s)) #00000012;
}

/*
  small screen helpers
  e.g. when phone keyboard is out so easier to see
*/
@media screen and (max-width: 600px) {
  @media screen and (max-height: 600px) {
    html {
      --s: calc(1.1 * (0.5vw + 0.5vh));
    }
    html,
    textarea {
      -webkit-text-stroke: 0.15px;
      text-shadow: var(--colorDarker1) 0px 0.35px 0.35px;
    }
  }
}

@media screen and (min-aspect-ratio: 14/10) {
  html {
    --currentScale: var(--horizontalScale);
  }
}
