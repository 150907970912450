/* the blue floating bar for style */
.bar {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 15vw; /* modified by js */
  transform: skewY(-6deg);
  transform-origin: top left;
  background-color: var(--colorBitcoinBlue);
  z-index: 100;
}

/* wrapper of the domain name / alias text */
.domain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(0.5 * var(--s));
  margin: calc(0.1 * var(--s));
  margin-left: 1vw;
  margin-bottom: 1vw;
  z-index: 100;
}

.alias {
  color: var(--colorBackground);
  /* margin-top - modified by js */
  /* font size - modified by js */
}

.ext {
  color: var(--colorBitcoinOrange);
}

.alias,
.ext {
  transform: translateX(calc(2 * var(--s)));
  cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                   @media                                   */
/* -------------------------------------------------------------------------- */

/* prevents rendering outside page range */
.cutOverflow {
  top: 0%;
  left: 0%;
  position: absolute;
  overflow: hidden;
  height: 15vw; /* modified by js */

  width: 100%;
}

.spacer {
  display: block;
  position: static;
  /* 8vw old value */
  height: var(--headerOffset);
}

.wrapper > * {
  transform-origin: top left;
  transform: scale(var(--verticalScale));
  z-index: 50;
}

@media screen and (min-aspect-ratio: 14/10) {
  .wrapper > * {
    transform: scale(var(--horizontalScale));
  }

  .cutOverflow {
    width: calc(100vw / var(--horizontalScale));
  }
}
