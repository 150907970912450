/* total height: padding 2s, 2.5s button text line, 2s button padding = 6.0s */
.nav {
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  color: var(--colorLightText);
  vertical-align: middle;
  word-wrap: normal;
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  padding: calc(1 * var(--s));
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: calc(2.5 * var(--s));
  transform: scale(var(--verticalScale));
}

.leftCorner {
  bottom: calc(0 * var(--s));
  left: calc(0 * var(--s));
  /* height: var(--navbarHeight); */
  position: absolute;
  display: grid;
  grid:
    'wallet pageNum api' auto
    / auto auto auto;
  justify-items: center;
  align-items: end;
  transform: scale(0.999);
  z-index: 9;

  /* so it doesn't block view clicking */
  pointer-events: none;
}

.leftCorner > .wallet {
  box-sizing: border-box;
  min-height: calc(var(--navbarHeight));
  grid-area: wallet;
  padding: calc(0.75 * var(--s)) calc(1 * var(--s));
  /* z-index: 5; */
  transform: scale(0.999);

  /* allow clicking */
  pointer-events: auto;
}

.leftCorner > .pageNum {
  grid-area: pageNum;
  box-sizing: border-box;
  bottom: calc(var(--navbarHeight) / 2);
  transform: translateY(50%);
  color: var(--colorLightText);
  box-sizing: border-box;
  position: relative;
  font-size: calc(2.3 * var(--s));
  line-height: calc(2.5 * var(--s));
  float: left;
  padding: 0 calc(2 * var(--s));
  vertical-align: middle;
}

.leftCorner > .api {
  box-sizing: border-box;
  position: relative;
  /* height: calc(var(--navbarHeight)); */
  padding: 0 calc(2 * var(--s));
  grid-area: api;

  /* allow clicking */
  pointer-events: auto;
}

.warning {
  display: inline-block;
  position: fixed;
  bottom: calc(var(--navbarHeight) + (3 * var(--s)));
  right: calc(1 * var(--s));
  background-color: var(--colorBackground);

  font-size: calc(2.1 * var(--s));

  color: var(--colorWarning);
  vertical-align: middle;
  text-align: left;
  opacity: 0.95;

  border-radius: calc(0.5 * var(--s));
}
.warning > div {
  padding: calc(1 * var(--s)) calc(2 * var(--s));
  padding-right: calc(3.2 * var(--s));
}
.warning > input {
  visibility: hidden;
  position: absolute;
}
.warning > label {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: calc(1.2 * var(--s));
  height: 0;
  font-weight: bold;
  width: 0;
  line-height: 0;
  margin: calc(0.75 * var(--s));
  padding: calc(1 * var(--s));
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  text-indent: calc(-0.5 * var(--s));
  cursor: pointer;
  opacity: 0.6;
}
.warning > label:hover {
  box-shadow: 0 0 0 calc(0.05 * var(--s)) var(--colorWarning);
  opacity: 1;
}
.warning > label:active {
  transform: translateY(calc(0.1 * var(--s)));
}
.warning > input[type='checkbox']:checked ~ * {
  display: none;
}

.overflowMenu {
  opacity: 0;
  --finalOpacity: 1;

  flex-basis: 100%;
  flex-wrap: wrap;
  position: fixed;
  border-radius: calc(0.5 * var(--s));
  transition: opacity 0.15s;

  -webkit-backdrop-filter: blur(calc(0.5 * var(--s))) opacity(1) brightness(1.1)
    saturate(90%) contrast(0.8);
  backdrop-filter: blur(calc(0.5 * var(--s))) opacity(1) brightness(1.1)
    saturate(90%) contrast(0.8);

  transform-origin: top left;
  transform: scale(1.15);
}

.overflowMenu > div {
  box-sizing: border-box;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: calc(1 * var(--s)) calc(2 * var(--s));
  border-radius: calc(0.5 * var(--s));

  box-shadow: inset 0 0 calc(0.25 * var(--s)) 0 var(--colorDarker1);
  background-color: var(--colorBrighter9);
}

.overflowMenu > div > .button {
  margin: calc(0.5 * var(--s));
  color: var(--colorDarker5);
}

.overflowMenu .button,
.nav .button,
.button {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: calc(2.5 * var(--s));
  margin: 0 calc(0.25 * var(--s));
  padding: calc(0.75 * var(--s)) calc(3 * var(--s));
  border-radius: calc(0.5 * var(--s));
  cursor: pointer;
}

.nav .button:hover,
.nav .button:hover {
  background-color: var(--colorDarker0_5);
}

.overflowMenu .button:hover {
  background-color: var(--colorDarker0_5);
}

.nav .selected {
  background-color: var(--colorDarker0_5);
}

.overflowMenu .selected {
  background-color: var(--colorBrighter9);
}

.button:active {
  transform: translateY(calc(0.1 * var(--s))) scale(0.95);
}

/* "..." button for handling collapsed menu options */
.collapsitron {
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: calc(0.5 * var(--s)) calc(2.5 * var(--s));
  margin: calc(0 * var(--s)) calc(0.5 * var(--s));

  vertical-align: middle;
  border-radius: calc(0.5 * var(--s));
}

.collapsitron:hover {
  box-shadow: inset 0 0 0 calc(0.25 * var(--s)) var(--colorDarker1);
}

@supports not (backdrop-filter: blur(1px)) {
  /* firefox doesn't support backdrop filters unless manually turned on... */
  .overflowMenu {
    /* background-color: rgba(199, 206, 218, 0.91); */
    --col1: rgba(200, 200, 200, 0.95);
    --col2: rgba(255, 255, 255, 0.95);
    background: linear-gradient(
      -45deg,
      var(--col1) 0%,
      var(--col2) 33%,
      var(--col1) 66%,
      var(--col2) 100%
    );
  }
  .overflowMenu > div {
    background-color: var(--colorBrighter8);
  }
  .overflowMenu > div > .button:hover {
    background-color: var(--colorDarker0_5);
  }
  .overflowMenu .selected {
    background-color: var(--colorDarker0_5);
  }
}

/* -------------------------------------------------------------------------- */
/*                                 menu button                                */
/* -------------------------------------------------------------------------- */

/* dots wrapper */

.dots {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  /* text size based */
  --iconSize: calc(2.2 * var(--s));

  /* smallest unit */
  --radius: calc(0.1 * var(--iconSize));

  /* only for centering */
  --centerX: calc(50% - var(--radius));
  --centerY: calc(50% - var(--radius));

  --offsetsXY: calc(4 * var(--radius));

  /* various animation fixes */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-filter: blur(0);
  filter: blur(0);

  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
  font-smoothing: antialiased !important;

  /* risky filters */
  filter: blur(0) opacity(0.2) brightness(100%);

  /* animation */
  transform: translateZ(0) scale(1, 1) rotate(0.001deg);
  transition: transform 0.3s;
}

/* positioned inside dots */

.dots > div,
.dots > div:before,
.dots > div:after {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  padding: var(--radius);
  border-radius: var(--radius);
  background-color: var(--colorBitcoinBlue);

  transform-origin: center;

  transform: translateZ(0) scale(1, 1) translate(0, 0) rotate(0.01deg);

  /* animation */
  transition: transform 0.3s, width 0.3s;
}

.dots > div {
  top: var(--centerY);
  left: var(--centerX);
}

.dots > div:before,
.dots > div:after {
  content: '';
  top: 0;
  left: 0;
}

.dots > div:before {
  left: calc(var(--centerX) - var(--offsetsXY));
}

.dots > div:after {
  left: calc(var(--centerX) + var(--offsetsXY));
}

/* once active */

.menuShown .dots {
  transform: translateZ(0) scale(1, 1) translate(0, 0) rotate(179deg);
  filter: blur(0) opacity(0.2) brightness(100%);
}

.menuShown .dots > div,
.menuShown .dots > div:before,
.menuShown .dots > div:after {
}

.menuShown .dots > div {
  transform: translateZ(0) scale(1, 1) translate(0, 0) rotate(0deg);
}

.menuShown .dots > div:before {
  transform: translateZ(0) scale(1, 1)
    translate(calc(-2 * var(--radius)), calc(0 * var(--radius))) rotate(135deg);
  width: calc(2.82 * var(--offsetsXY));
}

.menuShown .dots > div:after {
  transform: translateZ(0) scale(1, 1)
    translate(
      calc(-2 * var(--offsetsXY) - 2 * var(--radius)),
      calc(0 * var(--radius))
    )
    rotate(45deg);
  width: calc(2.82 * var(--offsetsXY));
}

.menuShown .dots:not(:active) {
  animation: blurme 0.3s steps(10) forwards;
}

@keyframes blurme {
  %0 {
    filter: blur(0) opacity(0.2) brightness(100%);
  }
  50% {
    filter: blur(calc(0.7 * var(--radius))) opacity(0.2) brightness(100%);
  }
  100% {
    filter: blur(0) opacity(0.2) brightness(100%);
  }
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .nav {
    transform-origin: bottom right;
    transform: scale(var(--horizontalScale));
    width: calc(100% / var(--horizontalScale));
  }

  .overflowMenu {
    transform: scale(calc(1.1 * var(--horizontalScale)));
    transform-origin: top left;
  }
  .leftCorner,
  .warning {
    transform-origin: bottom left;
    transform: scale(var(--horizontalScale));
  }
}
