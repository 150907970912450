.wrapper {
  color: var(--colorLightText);
  margin-bottom: calc(var(--navbarHeight) / 2);
  transform: translateY(50%);
}

.spinner {
  display: block;
  position: fixed;
  top: -40vh;
  left: 50vw;
  z-index: 1000;
  transform: translate(-50%, 0);

  --widthSpinner: calc(15vmin);
}

/* have to undo scaling */
@media screen and (min-aspect-ratio: 14/10) {
  .spinner {
    transform-origin: bottom left;
    left: calc(50vw / var(--horizontalScale));
    top: calc(-25vh / var(--horizontalScale));
    transform: scale(calc(1 / var(--horizontalScale))) translate(-50%, 0);
  }
}
