.fees {
  width: 100%;
  box-sizing: border-box;
  font-size: calc(2.5 * var(--s));
  color: var(--colorBitcoinBlue);
}

.fees__rate {
  position: relative;
  display: inline-block;
  width: calc(20 * var(--s));
  overflow-x: visible;
  padding-top: calc(3.1 * var(--s));
}

.fees__rate aside {
  width: calc(25 * var(--s));
  top: 0;
  line-height: calc(2.5 * var(--s));
  left: calc(-2 * var(--s));
  font-size: calc(2.3 * var(--s));
  position: absolute;
  display: block;
  color: var(--colorBitcoinBlue);
  opacity: 0.7;
  text-align: left;
}

.fees__rate {
  margin-left: calc(2 * var(--s));
}

.fees__apicall {
  display: inline-block;
  position: relative;
}

.fees__feeSelection {
  position: absolute;
  top: 100%;
  right: 0%;
  width: 200%;
  padding: calc(2 * var(--s));
  background-color: var(--colorBitcoinBlue);
  border-radius: calc(0.5 * var(--s));
  opacity: 0.97;
  z-index: 100;
}

.fees__feeSelection__choice {
  font-size: calc(2 * var(--s));
  margin: calc(1 * var(--s));
  padding: calc(0.75 * var(--s)) calc(1 * var(--s));
  background-color: var(--colorBrighter2);
  border-radius: calc(0.5 * var(--s));
  cursor: pointer;
  color: var(--colorBackground);
  text-align: center;
}

.fees__feeSelection__choice:hover {
  background-color: var(--colorBrighter3);
}

.fees__feeSelection__choice:active {
  transform: translateY(calc(0.2 * var(--s)));
}
