.wrapper {
  box-sizing: border-box;

  padding: 0;
  height: calc(var(--dh));
  width: 100%;
  z-index: 0;
}

/*
  content will be scrolled,
  scrollbar starts at bottom of headder spacer,
  padding in scroller ensures no text at top,
  scrollbar ends at navbar,
  overflow-y: scroll makes sure no resizing on scrollbar showing
*/
.scroller {
  box-sizing: border-box;

  height: calc(var(--dh) - var(--headerOffset));
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;
}

/* -------------------------------------------------------------------------- */
/*                                   @media                                   */
/* -------------------------------------------------------------------------- */

.scroller {
  padding-top: calc(var(--headerWhitespace));
}

/* content will get scaled */
.horizontalScaler {
  width: 100%;

  transform-origin: top;
  transform: scale(var(--verticalScale));
}

@media screen and (min-aspect-ratio: 14/10) {
  .scroller {
    padding-top: calc(var(--headerWhitespace) * var(--horizontalScale));
  }

  .horizontalScaler {
    transform: scale(var(--horizontalScale));
  }
}
