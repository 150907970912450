.wrapper {
  height: 100%;
  width: 100%;
}

.contentWrapper > div {
  padding: calc(2 * var(--s)) 0;
}

.title {
  font-size: calc(5 * var(--s));
  margin-bottom: calc(3 * var(--s));
}

/* wide screen settings */
@media screen and (min-aspect-ratio: 14/10) {
  .contentWrapper {
    transform-origin: top;
    transform: scale(var(--horizontalScale));
  }
}
