/* -------------------------------------------------------------------------- */
/*                                     v2                                     */
/* -------------------------------------------------------------------------- */

.outershell2 {
}

/* all divs get this for reset */
.btcLogo {
  /* btcLogoInputSize becomes height & width, u is 1/1000 that */
  --btcLogoUnit: calc(var(--btcLogoInputSize, 50vmin) / 1000);
  --colorBitcoinOrange: rgb(255, 155, 0);
  --colorText: white;
  /* --btcLogoInputSize: 80vh; */
  display: inline-block;
  position: absolute;
  transform: scale(0.99999) rotate(0.00001deg) translate(0, 0) translateZ(0);
  opacity: 0.99999;
  filter: blur(0);
  width: var(--btcLogoInputSize, 50vmin);
  height: var(--btcLogoInputSize, 50vmin);
  font-size: var(--btcLogoInputSize, 50vmin);
  line-height: 0;
  transform-origin: top left;
  font-family: 'Ubuntu';
  font-weight: bold;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
}

/* need 1 div to cut off extra stuff and serve as wrapper */
.cutoffs {
  /* becomes relative coods for others */
  position: relative;
  /* hide stuff */
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* need 1 div to to make yellow background and slight offset */
.background {
  border-radius: var(--btcLogoInputSize, 50vmin);
  background-color: var(--colorBitcoinOrange);
  transform: scale(0.99) rotate(0.00001deg)
    translate(calc(5 * var(--btcLogoUnit)), calc(5 * var(--btcLogoUnit)));
}

.theB {
  color: var(--colorText);
  background-color: transparent;

  width: calc(85 * var(--btcLogoUnit));
  height: calc(120 * var(--btcLogoUnit));

  box-shadow: calc(345 * var(--btcLogoUnit)) calc(358 * var(--btcLogoUnit)) 0 0
      var(--colorText),
    calc(185 * var(--btcLogoUnit)) calc(358 * var(--btcLogoUnit)) 0 0
      var(--colorText),
    calc(345 * var(--btcLogoUnit)) calc(-425 * var(--btcLogoUnit)) 0 0
      var(--colorText),
    calc(185 * var(--btcLogoUnit)) calc(-425 * var(--btcLogoUnit)) 0 0
      var(--colorText);

  transform: scale(0.83) rotate(20deg)
    translate(calc(460 * var(--btcLogoUnit)), calc(333 * var(--btcLogoUnit)));
}
